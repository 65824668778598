<template>
  <!-- Remove Subtitle and Audio when there are only 1 option available. -->
  <div
    v-if="
      parsedAudioTracks?.length > 1 ||
      store.playerStore.player.textTracks?.length >= 1
    "
  >
    <tippy :arrow="false" theme="blur" interactive>
      <ButtonsIcon
        id="subs"
        :enable-hover="false"
        :breakpoints="store.iconBreakpoints"
        iconName="IconSubtitles"
      ></ButtonsIcon>
      <template #content>
        <TippyMenu>
          <div class="w-full flex divide-x divide-white/[0.65] border-inherit">
            <div
              v-if="parsedAudioTracks?.length > 1"
              class="flex-grow w-full flex flex-col"
            >
              <span
                class="bg-white/10 text-body-big text-white opacity-[0.87] text-center px-6 py-4"
              >
                {{ $t("Audio") }}
              </span>
              <div
                v-for="(audioTrack, key) in parsedAudioTracks"
                :key="audioTrack.index"
                :class="[audioTrack.index == audioTrack.index && 'bg-white/30']"
                @click="onChangeAudioTrack(audioTrack)"
                class="px-6 py-3 flex justify-between items-center gap-x-4 cursor-pointer"
              >
                <span class="text-body-big text-white opacity-[0.87]">
                  {{ audioTrack.label }}
                </span>
              </div>
            </div>
            <div
              v-if="store.playerStore.player.textTracks?.length >= 1"
              class="flex-grow w-full flex flex-col border-inherit"
            >
              <span
                class="bg-white/10 text-body-big text-white opacity-[0.87] text-center px-6 py-4 border-inherit"
              >
                {{ $t("Subtitles") }}
              </span>
              <button
                :key="textTrack?.index"
                :class="[
                  store.playerStore.player.textTrack == textTrack.index &&
                    'bg-white/30',
                ]"
                v-for="textTrack in store.playerStore.player.textTracks"
                class="px-6 py-3 flex justify-between items-center gap-x-4 cursor-pointer"
                @click="onChangeTextTrack(textTrack)"
              >
                <span class="text-body-big text-white opacity-[0.87]">
                  {{ textTrack?.name }}
                </span>
              </button>
              <div
                :class="[
                  store.playerStore.player?.textTrack == -1 && 'bg-white/30',
                ]"
                @click="onChangeTextTrack({ index: -1 })"
                class="px-6 py-3 flex justify-between items-center gap-x-4 cursor-pointer"
              >
                <span class="text-body-big text-white opacity-[0.87]">
                  {{ $t("Off") }}
                </span>
                <Icon name="IconCheck" size="24"></Icon>
              </div>
            </div>
          </div>
        </TippyMenu>
      </template>
    </tippy>
  </div>
  <ButtonsIcon
    v-else
    :enable-hover="false"
    disabled
    class="!cursor-none touch-none pointer-events-none"
  ></ButtonsIcon>
</template>

<script setup>
import { usePlayerUi } from "@/stores/playerUi";
const store = usePlayerUi();

const props = defineProps({
  playingInfo: {
    type: Object,
    required: true,
  },
});

const parsedAudioTracks = computed(() => {
  return store.playerStore.player?.audioTracks?.map((a) => {
    return {
      ...a,
      label: a.language == "en" ? "English" : "Arabic",
    };
  });
});

const onChangeAudioTrack = (audioTrack) => {
  store.playerStore.player.audioTrack = audioTrack.index;

  store.playerStore.updatePlayerConfig("applicationAnalytics", {
    sesContentLanguage: audioTrack.index,
  });
};

const onChangeTextTrack = (textTrack) => {
  store.playerStore.player.textTrack = textTrack.index;
};
</script>

<style lang="scss" scoped></style>
