<template>
  <div
    :id="containerId"
    class="fixed z-[999] left-0 top-0 right-0 bottom-0 bg-black"
  >
    <div class="w-full h-full aspect-video">
      <video
        :muted="isMuted"
        :class="[imageClass]"
        id="trailerFullScreenContainer"
        ref="trailerFullScreenContainer"
        playsinline
        muted
        autoplay
        class="z-0 w-full h-full"
      ></video>
      <img
        v-if="playBackHasEnded"
        class="z-0 w-full h-full absolute top-0 object-cover bg-center bg-cover bg-no-repeat"
        :src="watchStore.media.images.banner"
      />
    </div>
    <PlayerOverlay class="fixed top-0 start-0 w-full h-full">
      <template #header1-start>
        <ButtonsIcon
          icon-name="IconArrowLeft"
          class="touch-auto pointer-events-auto rtl:rotate-180"
          @click="trailerStore.trailerPlayinfo = null"
        ></ButtonsIcon>
      </template>
      <template #header1-end>
        <div class="flex gap-x-4 z-10 relative">
          <PlayerOverlayControlsInfo
            :media="watchStore?.media"
          ></PlayerOverlayControlsInfo>
          <UiShareButtonPlayer
            v-if="watchStore?.media?.shareLink"
            :title="watchStore?.h1Label ?? ''"
            :url="watchStore?.media?.shareLink"
            class="touch-auto pointer-events-auto"
          />
        </div>
      </template>
      <template #header2-end>
        <div
          class="w-full flex flex-row justify-between pointer-events-none z-0 items-start"
        >
          <div class="flex flex-row text-white gap-2 items-center">
            <h1 class="text-title-medium">
              {{
                watchStore?.media?.tvShowSeries?.name ??
                watchStore?.media?.aggregatedVideo?.name
              }}
            </h1>
            <h2 class="text-label-medium">{{ $t("trailer") }}</h2>
          </div>
          <div class="thumbnail aspect-video h-16">
            <img
              :src="watchStore?.media?.images?.logo"
              class="drop-shadow-md"
            />
          </div>
        </div>
      </template>
      <template #actionables>
        <div class="overlay__actionables w-full"></div>
      </template>
      <template #scrubber>
        <PlayerOverlayScrubber
          class="overlay__scrubber px-4 md:px-6 lg:px-10"
        ></PlayerOverlayScrubber>
      </template>
      <template #controls-start>
        <slot name="controls-start">
          <PlayerOverlayControlsPlayButton @click.stop="togglePlayHandle" />
          <template v-if="isLgAndUp">
            <ButtonsIcon
              :enable-hover="false"
              :breakpoints="store.iconBreakpoints"
              iconName="IconSkipBackTen"
              @click.stop="store.playerStore.scrubBack"
            ></ButtonsIcon>
            <ButtonsIcon
              :enable-hover="false"
              :breakpoints="store.iconBreakpoints"
              iconName="IconSkipForwardTen"
              @click.stop="ForwardHandle"
            ></ButtonsIcon>
          </template>
          <PlayerOverlayControlsVolume
            :volume="volume"
            :is-muted="isMuted"
            @on-change="(v) => store.setVolume(v)"
            @on-click="$emit('on-toggle-volume')"
          >
          </PlayerOverlayControlsVolume>
        </slot>
      </template>
      <template #controls-center>
        <slot name="controls-center"></slot>
      </template>
      <template #controls-end>
        <PlayerOverlayControlsSubtitle
          :playing-info="watchStore.playInfo"
        ></PlayerOverlayControlsSubtitle>
        <PlayerOverlayControlsSpeed></PlayerOverlayControlsSpeed>
        <PlayerOverlayControlsQuality></PlayerOverlayControlsQuality>
        <PlayerOverlayControlsPip></PlayerOverlayControlsPip>
        <PlayerOverlayControlsFullscreen></PlayerOverlayControlsFullscreen>
      </template>
    </PlayerOverlay>
  </div>
</template>

<script setup>
import { PLAYERS } from "@/constants";
import { useWatchStore } from "@/stores/watch";
import { usePlayerUi } from "@/stores/playerUi";
import { useTrailerStore } from "@/stores/trailer";
import { usePlayerStoreWithID } from "@/stores/playerWithId";
const { videoInterations } = useADtvGTM();

const playerStore = ref();
playerStore.value = usePlayerStoreWithID(PLAYERS.TRAILER_PLAYER)();

const { isLgAndUp } = useBreakpoints();

const watchStore = useWatchStore();
const store = usePlayerUi();
store.setPlayer(PLAYERS.TRAILER_PLAYER);
const trailerStore = useTrailerStore();

const containerId = `${PLAYERS.TRAILER_PLAYER}-container`;

onMounted(async () => {
  await playerStore.value.$reset();
  store.registerKeyboardEvents();
  playTrailer();
});

onBeforeUnmount(async () => {
  if (document.pictureInPictureElement) {
    await document.exitPictureInPicture();
  }

  await store.$reset();
  await watchStore.$reset();
});
const playTrailer = async () => {
  await useAllPlayers().pauseAll();
  playerStore.value = usePlayerStoreWithID(PLAYERS.TRAILER_PLAYER)();
  playerStore.value.useLocalPcw = false;
  await playerStore.value.initialize("trailerFullScreenContainer");
  await playerStore.value.loadMedia(
    { url: trailerStore.trailerPlayinfo.url },
    {
      extraOpts: {
        autostart: true,
      },
    },
    {},
    {
      sesContentType: "Trailer",
      sesSessionType: "Trailer",
    }
  );
};

const playBackHasEnded = computed(() => {
  return store.playerStore?.playbackState === "ended";
});

const togglePlayHandle = () => {
  store.playerStore.togglePlayback();
  if (store.playerStore.isPlaying) {
    videoInterations("play", {
      media_name: watchStore.h1Label,
      show_id: watchStore?.media?.aggregatedVideo?.assetExternalId,
    });
  } else {
    videoInterations("pause", {
      media_name: watchStore.h1Label,
      show_id: watchStore?.media?.aggregatedVideo?.assetExternalId,
    });
  }
};

const ForwardHandle = () => {
  store.playerStore.scrubForward();
  videoInterations("forward", {
    media_name: watchStore.h1Label,
    show_id: watchStore?.media?.aggregatedVideo?.assetExternalId,
  });
};

const hasSkipIntro = parseInt(watchStore.playInfo?.Skip_Intro || 0);
if (hasSkipIntro > 0) {
  watch(
    () => store.playerStore.currentTime,
    (time) => {
      const isInRange = time > 1 && time < hasSkipIntro;
      if (isInRange) {
        store.actionableState.skipIntro = true;
      } else {
        store.actionableState.skipIntro = false;
      }
    }
  );
}

watch(
  () => store.playerStore?.playbackState,
  (playbackState) => {
    if (playbackState) {
      store.isLoading = false;
    }

    if (playbackState === "ended") {
      store.isUiShowing = true;
      store.preventHideUI = true;
      store.hideUi();
    } else {
      store.preventHideUI = false;
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped></style>
